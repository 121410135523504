window.log = console.log;

(function($) {
	$.fn.distance = function (options) {
		var defaults = {
			top: 0,
			time: 150
		};
 		var opts = $.extend({}, defaults, options);   

		return this.each(function() { 
			var _this = $(this);
		    $(window).scroll(actionEvent).resize(actionEvent);
		    function actionEvent() {
		        var _windowScrollTop = $(window).scrollTop();
		        var viewTop = parseInt(($(window).height() - _this.height()) / 2) - 10;
		        viewTop = opts.top > 0 ? opts.top : viewTop;
		        _this.stop().animate({
	            	top: _windowScrollTop + viewTop
	            }, opts.time);
		    }
		    actionEvent();
		}); 
	};
})(jQuery);

$(function(){
	$('.cate-list').on('click', '.arrow-click', function(e) {
	    e.preventDefault();
	    var _div = $(this).parent().find('.map-category-list');
	    if (_div.length > 0) {
	    	if (_div.hasClass("show")) {
	    		$(this).removeClass('arrow-03');
				_div.stop().removeClass('show');
	    	} else {
	    		$(this).addClass('arrow-03');
				_div.stop().addClass('show');
	    	}
			return false;
		}
		window.location.href = $(this).attr('href'); 
	});	
})
